import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  DataGridPro,
  GridColDef,
  GridToolbarContainer,
} from "@mui/x-data-grid-pro";
import DownloadIcon from "@mui/icons-material/Download";
import InfoIcon from "@mui/icons-material/Info";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Stack,
  Button,
  Tooltip,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import EmailIcon from "@mui/icons-material/Email";
import axiosInstance from "../utils/axiosInstance";
import NoRows from "../components/NoRows";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://hhsresidential.com/">
        HHS Residential
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function Home() {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [status, setStatus] = React.useState<
    "PendingApproval" | "Approved" | "Rejected"
  >("PendingApproval");
  const [open, setOpen] = React.useState(false);
  const [selectionModel, setSelectionModel] = React.useState<string[]>([]);
  const [documents, setDocuments] = React.useState<
    {
      checkNumber: string;
      createdDate: string;
      email: string;
      name: string;
      projectName: string;
      status: string;
      statusAuthor: string;
      statusDate: string;
      vendorName: string;
    }[]
  >([]);

  const fetchDocuments = () => {
    setLoading(true);
    axiosInstance
      .get(`/api/documents/${status}`)
      .then((res) => {
        setDocuments(res.data.results);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const handleChange = (event: SelectChangeEvent) => {
    setStatus(
      event.target.value as "PendingApproval" | "Approved" | "Rejected"
    );
  };

  const handleApprove = () => {
    axiosInstance
      .post("/api/workflow/", {
        action: "Approve",
        fileNames: selectionModel,
      })
      .then((res) => {
        fetchDocuments();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleReject = () => {
    axiosInstance
      .post("/api/workflow/", {
        action: "Reject",
        fileNames: selectionModel,
      })
      .then((res) => {
        fetchDocuments();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleResendEmail = () => {
    axiosInstance
      .post("/api/workflow/", {
        action: "ResendEmail",
        fileNames: selectionModel,
      })
      .then((res) => {
        fetchDocuments();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleDownloadItems = (fileName: string) => {
    axiosInstance
      .get(`/api/document/${fileName}`)
      .then((res) => {
        window.open(res.data.results.sasUri, "_blank", "noreferrer");
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleInfoOpen = () => {
    setOpen(true);
  };

  const handleInfoClose = () => {
    setOpen(false);
  };

  const columns: GridColDef[] = [
    {
      field: "status",
      headerName: "Status",
      minWidth: 130,
      flex: 1,
      renderCell: (params) => {
        if (params?.row?.status === "PendingApproval") {
          return "Pending Approval";
        }
        return params?.row?.status;
      },
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 140,
      renderCell: (params) => params?.row?.createdDate.split(" ")[0],
    },
    {
      field: "statusDate",
      headerName: "Status Date",
      flex: 1,
      headerAlign: "center",
      align: "center",
      minWidth: 140,
      renderCell: (params) => params?.row?.statusDate.split(" ")[0],
    },
    {
      field: "checkNumber",
      headerName: "Check Number",
      minWidth: 120,
      flex: 1,
    },
    { field: "vendorName", headerName: "Vendor Name", minWidth: 360, flex: 1 },
    {
      field: "projectName",
      headerName: "Project Name",
      minWidth: 300,
      flex: 1,
    },
    { field: "email", headerName: "Email", minWidth: 400, flex: 1 },
    {
      field: "statusAuthor",
      headerName: "Status Author",
      minWidth: 300,
      flex: 1,
    },
    { field: "name", headerName: "File Name", minWidth: 600, flex: 1 },
    {
      field: "download",
      headerName: "Download",
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <Tooltip title="Download File">
            <IconButton
              onClick={() => handleDownloadItems(params?.row?.name as string)}
            >
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  React.useEffect(() => {
    fetchDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  function Toolbar() {
    return (
      <GridToolbarContainer
        sx={{
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <Box
          flex={1}
          marginY={0.5}
          padding={0.5}
          display="flex"
          width={"100%"}
          justifyContent="space-between"
        >
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={status}
                size="small"
                label="Status"
                onChange={handleChange}
              >
                <MenuItem value={"PendingApproval"}>Pending Approval</MenuItem>
                <MenuItem value={"Approved"}>Approved</MenuItem>
                <MenuItem value={"Rejected"}>Rejected</MenuItem>
              </Select>
            </FormControl>
          </Box>
          {Boolean(selectionModel.length) && (
            <>
              <Box
                sx={{
                  minWidth: 120,
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "flex",
                    flexDirection: "column-reverse",
                  },
                }}
              >
                <Stack spacing={1} direction="row">
                  {status !== "Approved" && (
                    <Button
                      variant="outlined"
                      startIcon={<CheckCircleOutlineIcon />}
                      size="medium"
                      color="success"
                      onClick={handleApprove}
                      sx={{ marginBottom: 0 }}
                    >
                      Approve
                    </Button>
                  )}
                  {status !== "Rejected" && (
                    <Button
                      variant="outlined"
                      startIcon={<CancelIcon />}
                      size="medium"
                      color="error"
                      onClick={handleReject}
                    >
                      Reject
                    </Button>
                  )}
                  {status === "Approved" && (
                    <Button
                      variant="outlined"
                      startIcon={<EmailIcon />}
                      size="medium"
                      onClick={handleResendEmail}
                    >
                      Resend Email
                    </Button>
                  )}
                </Stack>
              </Box>
              <Box
                sx={{
                  minWidth: 120,
                  display: { xs: "block", sm: "block", md: "none" },
                }}
              >
                {status !== "Approved" && (
                  <Tooltip title="Approve">
                    <IconButton onClick={handleApprove}>
                      <CheckCircleOutlineIcon color="success" />
                    </IconButton>
                  </Tooltip>
                )}
                {status !== "Rejected" && (
                  <Tooltip title="Reject">
                    <IconButton onClick={handleReject}>
                      <CancelIcon color="error" />
                    </IconButton>
                  </Tooltip>
                )}
                {status === "Approved" && (
                  <Tooltip title="Resend Email">
                    <IconButton onClick={handleResendEmail}>
                      <EmailIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            </>
          )}
        </Box>
      </GridToolbarContainer>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth={false}>
        <CssBaseline />
        <Box
          width={"100%"}
          sx={{
            marginTop: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box>
            <Stack direction="row">
              <Typography variant="h6" gutterBottom fontFamily="azo-sans-web">
                HHS Residential Lien Waivers
              </Typography>
              <Tooltip title="Info">
                <InfoIcon
                  onClick={handleInfoOpen}
                  sx={{ mt: -1, cursor: "pointer" }}
                  color="disabled"
                />
              </Tooltip>
            </Stack>
          </Box>
          <Box sx={{ height: "75vh", width: "100%", mt: 2 }}>
            {documents && (
              <DataGridPro
                checkboxSelection
                disableRowSelectionOnClick
                columns={columns}
                rows={documents}
                rowCount={documents.length}
                getRowId={(row) => row.name}
                pagination
                onRowSelectionModelChange={(newSelection) => {
                  setSelectionModel(newSelection as any);
                }}
                slots={{
                  noRowsOverlay: NoRows,
                  toolbar: Toolbar,
                }}
                loading={loading}
              />
            )}
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
      <Dialog
        open={open}
        onClose={handleInfoClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title">Need help?</DialogTitle> */}
        <DialogContent>
          <DialogContentText
            sx={{
              fontWeight: 700,
              lineHeight: "24px",
              fontSize: "16px",
              color: "black",
            }}
          >
            To manage lien waivers, please follow these steps:
          </DialogContentText>
          <List sx={{ listStyleType: "disc" }}>
            <ListItem>
              <ListItemText
                primary="Locate the table that showcases a comprehensive list of lien
              waivers."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="To filter the list according to status, choose from the following
              options in the dropdown."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Select the lien waivers you would like to process by ticking the
                checkboxes next to each entry."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Depending on your desired action, click one of the three available
                action buttons: Approve Selected Waivers, Reject Selected Waivers,
                or Resend Emails for Selected Waivers."
              />
            </ListItem>
            <br />
            <DialogContentText
              sx={{
                fontWeight: 500,
                lineHeight: "24px",
                fontSize: "16px",
                color: "black",
              }}
            >
              By following these guidelines, you can efficiently manage your
              lien waivers in an organized manner. If you require any further
              assistance, please do not hesitate to reach out to our support
              team.
            </DialogContentText>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleInfoClose}>Okay</Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}
