import React from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { PageLayout } from "./components/PageLayout";
import "./styles/App.css";
import Home from "./pages/Home";
import { Container, Box } from "@mui/material";
import { SignInHero } from "./components/SignInHero";
import { LicenseInfo } from "@mui/x-license-pro";
/**
 * Renders information about the signed-in user or a button to retrieve data about the user
 */

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY);

const ProfileContent = () => {
  return <Home />;
};

/**
 * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */
const MainContent = () => {
  return (
    <div className="App">
      <AuthenticatedTemplate>
        <ProfileContent />
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Container sx={{ mt: 10 }}>
          <Box mx={2}>
            <h5 className="card-title">Please sign-in to acess.</h5>
          </Box>
          <SignInHero />
        </Container>
      </UnauthenticatedTemplate>
    </div>
  );
};

export default function App() {
  return (
    <PageLayout>
      <MainContent />
    </PageLayout>
  );
}
