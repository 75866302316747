import { AppBar, Toolbar, Box, Typography } from "@mui/material";
import React from "react";

type Props = {
  headerRight: React.ReactNode;
};

const Header = (props: Props) => {
  return (
    <AppBar position="static" sx={{ backgroundColor: "#003057" }}>
      <Toolbar>
        <Box display="flex" flex={1} justifyContent="space-between">
          <Box>
            <Typography
              variant="h6"
              sx={{
                fontFamily: "azo-sans-web",
              }}
            >
              <span
                style={{
                  fontWeight: 700,
                }}
              >
                HHS |{" "}
              </span>
              RESIDENTIAL
            </Typography>
          </Box>
          {props.headerRight}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
